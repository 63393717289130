@import "bourbon";

%hover-transition {
    @include transition(transform 0.6s ease-out 0s, all 0.8s ease-out 0s);
}

@mixin hover-grayscale-target($name, $grayscale) {

    &.hover-grayscale-target-#{$name},
    .hover-grayscale-target-#{$name} {
        @extend %hover-transition;

        @include filter(grayscale($grayscale));
    }

}

@mixin hover-scale($scale) {
    .hover-scale:hover,
    .hover-scale-force {
        &.hover-scale-target,
        .hover-scale-target {
            @extend %hover-transition;

            @include transform(scale($scale, $scale));
        }
    }
}

@mixin hover-grayscale($name, $percent) {
    .hover-grayscale:hover,
    .hover-grayscale-force {
        @include hover-grayscale-target($name, $percent);
    }
}
