
@mixin bottom-arrow($height, $color, $translate-y: 0px) {
    &:after {
        display: block;
        background-color: transparent;
        content: "";
        opacity: 1;
        width: 0;
        height: 0;
        border: $height solid transparent;
        border-bottom-color: $color;
        border-top: none;
        position: absolute;
        left: 50%;
        bottom: 0;
        @include transform(translateX(-50%) translateY($translate-y));
        @include transition(none);
    }
}
