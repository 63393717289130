@mixin page-colors($page-color, $border-color: $page-color) {
    .more-button {
        border-color: $border-color;
    }
    .edit-button {
        border-color: $border-color;
    }
    .page-banner {
        border-color: $border-color;
    }
    #secondary-navigation a:after {
        background-color: $page-color;
    }
    .mobile-secondary-page-active:after {
        background-color: $page-color;
    }
    .close-x-button-page {
        &::before, &::after {
            background-color: $page-color;
        }
    }
    hr {
        border-color: $border-color;
    }
    .expandable-section {
        a {
            color: $page-color;
            &:hover {
                color: white;
            }
        }
    }

    .item-list {
        .row {
            border-color: $border-color;
        }
    }
}
