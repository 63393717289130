@mixin close-x-button-hover($name, $background-color) {
    .close-x-button-hover-#{$name}:hover {
        &::before, &::after {
            background-color: $background-color;
        }
    }
}

@mixin close-x-button($name, $background-color) {
    $width: 125%;
    $height: 10%;

    $top: (100% - $height) / 2;
    $left: (100% - $width) / 2;

    .close-x-button-#{$name} {

        width: 28px;
        height: 28px;
        position: relative;
        display: inline-block;
        float: right;
        cursor: pointer;
        opacity: 1;
        @include transition(all 0.3s);

        &::before, &::after {
            width: $width;
            height: $height;
            position: absolute;
            top: $top;
            left: $left;
            content: "";
            background-color: $background-color;
            border-radius: 2px;
        }
        &::before {
            @include transform(rotateZ(45deg) scaleX(1) scaleY(1) scaleZ(1));
            @include transform-origin(50% 50% 0);
        }
        &::after {
            @include transform(rotateZ(-45deg) scaleX(1) scaleY(1) scaleZ(1));
            @include transform-origin(50% 50% 0);
        }
    }

    @include close-x-button-hover($name, $background-color);
}