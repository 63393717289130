@import 'cemex-css/mixins.scss';
@import 'cemex-css/variables.scss';

@include page-colors($products-and-services-color);
@include expandable-sections-button-colors($products-and-services-accent-color);



#sections-tab .nav-tabs {

    border: none;
    background-color: $products-and-services-accent-color-darker;

    @include display(flex);

    > li {
        @include flex-grow(1);
        float: none;
        vertical-align: bottom;
        @include font-style(12px, bold);

        .icon {
            display: block;
            width: 50px;
            height: 50px;
            margin: 0 auto;
        }

        .title {
            display: block;
            text-transform: uppercase;
            margin-top: 5px;
        }

        .arrow {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:last-child:after {
            display: none;
        }

        &:after {
            content: "";
            width: 1px;
            height: 65%;
            background-color: $products-and-services-color;
            position: absolute;
            right: -1px;
            top: 0;
            opacity: 0.4;
        }

        > a,
        > a:hover,
        > a:focus,
        &.active > a,
        &.active > a:focus,
        &.active > a:hover {
            border: none;
            border-radius: 0;
            color: white;
            background-color: transparent;
        }

        > a {
            height: 100%;
            margin: 0;
            margin-left: 1px;
            padding: 15px 10px;
            outline: none;
            text-align: center;
            opacity: 0.5;
        }

        &.active > a,
        &:hover > a {
            opacity: 1;
        }

        &:first-child a {
            margin-left: 0;
        }

        &.active .arrow {
            @include bottom-arrow($height: 12px, $color: $products-and-services-accent-color);
        }

        @include media-x-small {
            font-size: 9px;

            .icon {
                width: 40px;
                height: 40px;
            }
        }

    }

}


.first-content {
    color: white;
    background-color: $products-and-services-accent-color;
    a {
        color: $products-and-services-color;
    }
}

.first-content,
.second-content {
    padding: 40px 0;
}

.expandable-section {

    > div {
        padding-top: 70px;
    }

    .row {
        border-top: 1px solid $products-and-services-color;
        padding: 30px 0;

        &:first-child {
            border: none;
        }

        .feed-image {
            width: 160px;
            height: 160px;
            margin-right: 30px;
            margin-bottom: 20px;
            float: right;

            img {
                max-width: none;
                min-width: 100%;
                min-height: 100%;
            }

            @include media-small {
                float: left;
            }
        }

    }

}

.page-banner {
    height: calc(100vh - 120px - 102px);
    h1 {
        display: inline-block;
        padding-top: 55px;
    }
}