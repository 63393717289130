
@mixin font-style($size, $weight, $line-height: 1.2em) {
    font-size: $size;
    font-weight: $weight;
    line-height: $line-height;
    margin: 0;
    padding: 0;
    position: relative;
}

