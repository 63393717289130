@mixin key-statistics($page-background-color) {
    .key-statistics {
        color: #fff;
        background-color: $page-background-color;
        padding-top: 60px;
        padding-bottom: 60px;
        .stat-icon,
        .stat-content {
            display: inline-block;
            vertical-align: top;
            color: white;
        }
        .stat-content {
            @include media-medium {
                vertical-align: top;
            }
        }
        .stat-icon {
            @include media-medium {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 20px;
                vertical-align: bottom;
                display: block;
                width: auto;
            }
        }
        .hero-text {
            font-size: 70px;
            font-weight: 700;
            margin-bottom: 40px;
            margin-left: -3px;
        }
        &:after {
            content: "";
            display: block;
            clear: both;
        }
        .column {
            &:after {
                content: "";
                position: absolute;
                top: 20px;
                height: 110px;
                right: 0;
                width: 1px;
                background-color: rgba(#FFF, 0.5);
            }
        }
        .stat-wrapper {
            padding: 15px;
            margin: auto;
            width: auto;
            width: initial;
            text-align: center;
            display: block;
            @include transition(all 0.5s ease);
            &:hover {
                @include transform(scale(1.03, 1.03));
            }
            /* Fix image blurry when zooming */
            img {
                @include transform(translateZ(0));
            }
        }
        h1 {
            font-size: 70px;
            font-weight: 700;
            line-height: 1em;
        }
        h2 {
            font-size: 51px;
            font-weight: 700;
            line-height: 1em;
        }
        h3 {
            font-size: 26px;
            line-height: 1em;
        }
        p {
            font-size: 15px;
            padding: 0;
            margin: 0;
            line-height: 1em;
        }
        .hero-text {
            @include media-small {
                font-size: 50px;
                line-height: 95%;
                margin-bottom: 10px;
            }
        }
        .column:last-child {
            &:after {
                display: none;
            }
        }
         @include media-small {
            .column {
                margin: 0;
                border: solid;
                border-width: 0 0 1px 0;
                border-color: rgba(#FFF, 0.5);
                padding: 10px 0;
                &:before,
                &:after {
                    display: none;
                }
            }
            .column {
                width: 100%;
            }
            .column:last-child {
                border: none;
            }
        }
    }
    .columns-5.key-statistics {
        .column {
            width: 20%;

            &:after {
                top: 20px;
                height: 80px;

                @include media-large {
                    top: 10px;
                    height: 70px;
                }
            }
        }
        @include media-medium {
            .column {
                margin: 0;
                border: solid;
                border-width: 0 0 1px 0;
                border-color: rgba(#FFF, 0.5);
                padding: 20px 0;
                &:before,
                &:after {
                    display: none;
                }
            }
            .column {
                width: 100%;
            }
            .col-md-2:last-child {
                border: none;
            }
        }
    }
}