@import '../variables.scss';
@mixin page-hero($page-color, $secondary-color) {
    .hero {
        height: 60vh;

        @include media-small {
            height: 220px;
        }

        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        clear: both;
        &:before {
            content: "";
            position: absolute;
            height: 70%;
            width: 100%;
            z-index: 101;

            @include linear-gradient(0deg, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8), $fallback: transparent);
        }
    }
    .hero-button {
        position: relative;
        a {
            top: 30px;
            right: 30px;
            color: #fff;
            font-size: 26px;
            font-weight: 700;
            z-index: 102;
            position: absolute;
            @include transition(all 0.5s ease);
            text-align: right;
            &:hover {
                color: $page-color;
                @include transform(translate(5px, 0));
            }
        }
    }
    .hero-square {
        height: 30vh;
        background-color: $page-color;
        color: white;
        white-space: nowrap;
        img {
            max-height: calc(30vh - 40px);
        }
        p {
            padding-bottom: 0;
        }
        .col-xs-12 {
            height: 30vh;
            @include display(flex);
            @include align-items(center);
        }
        .content {
            padding-top: 0;
            padding-bottom: 40px;
            padding-left: 10%;
            padding-right: 10%;
            width: 100%;
            @include order(1);
        }
        .background-cover {
            @include order(1);
        }
        .hero-square-link {
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            letter-spacing: 2px;
            line-height: 40px;

            height: 40px;
            width: 100%;
            position: absolute;
            bottom: 0;
            background-color: $secondary-color;
            z-index: 100;
            border: solid;
            border-width: 1px 0 0 0;
            border-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
            overflow: hidden;
            .hero-square-link-label {
                float: left;
                margin-left: 20px;
            }
            .hero-square-link-arrow {
                float: right;
                font-size: 18px;
                line-height: 38px;
                position: relative;
                z-index: 100;
                margin-right: 17px;
            }
            &:before {
                content: "";
                display: block;
                top: 0;
                bottom: 0;
                left: -20px;
                position: absolute;
                width: 0%;
                background-color: rgba(0, 0, 0, 0.2);
                opacity: 0;
                @include transition(all 0.5s ease-out);
            }
            &:hover:before {
                opacity: 1;
                width: 130%;
                background-color: rgba(0, 0, 0, 0.2);
                @include transition(all 0.5s ease-out);
            }
            &:hover .hero-square-link-label {
                padding-left: 5px;
                @include transition(all 0.5s ease-out);
            }
            &:focus {
                color: white;
            }
        }


        @include media-small {
            height: 220px;
            .col-xs-12 {
                height: 220px;
            }
        }
    }
    .blue-box {
        background-color: $blue-box-color;
        .hero-square-link {
            background-color: $blue-box-secondary-color;
        }
    }
    .blue-box .content {
        max-height: 100%;
    }
}