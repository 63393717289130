@mixin expandable-sections-button-colors(
        $hover-color,
        $active-background-color: $hover-color,
        $active-border-color: $active-background-color,
        $active-hover-background-color: $hover-color,
        $active-hover-border-color: $active-hover-background-color) {

    .expandable-button {
        &:hover {
            color: $hover-color;
        }
    }

    .expandable-button-active {
        background-color: $active-background-color;
        border-color: $active-border-color;

        &:hover {
            background-color: $active-hover-background-color;
            border-color: $active-hover-border-color;
        }
    }

    .tabs-container {
        .tabs-menu {
            text-align: center;
            clear: both;
            padding: 0;

            li {
                a {
                    color: #fff;
                }

                @include transition(color 0.3s ease-out);

                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                margin: 0 30px 0 30px;

                &:hover {
                    color: #999;

                    &:after {
                        width: 100%;
                        @include transition(all 0.2s ease-out);
                        opacity: 1;
                    }
                }

                &:after {
                    content: "";
                    display: block;
                    height: 4px;
                    bottom: 0;
                    background-color: #ccc;
                    margin-top: 5px;
                    @include transition(all 0.7s ease-out);
                    width: 0;
                    opacity: 0;
                    position: relative;
                    z-index: 999;
                }

                &.current {
                    a {
                        color: $hover-color;
                    }

                    font-weight: 700;

                    &:hover {
                        color: white;
                    }

                    &:after {
                        width: 100%;
                        @include transition(all 0.2s ease-out);
                        opacity: 1;
                        background-color: $active-background-color;
                    }
                }

                @include media-small {
                    display: block;
                }
            }
        }

        .tab-content {
            padding-top: 70px;
            display: none;
            color: #fff;
            a {
                color: $hover-color;
            }
            table, tr, td {
                text-align: center;
                border: none;
            }
            /* Force table to not be like tables anymore */
            @include media-medium {
                table,
                thead,
                tbody,
                th,
                td,
                tr {
                    display: block;
                }

                /* Hide table headers (but not display: none;, for accessibility) */
                thead tr {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                td {
                    /* Behave like a "row" */
                    border: none;
                    position: relative;
                    white-space: normal;
                    text-align:left;
                }

                td:before {
                    /* Now like a table header */
                    position: absolute;
                    /* Top/left values mimic padding */
                    top: 6px;
                    left: 6px;
                    width: 45%;
                    padding-right: 10px;
                    white-space: nowrap;
                    text-align:left;
                    font-weight: bold;
                }

                /*
                Label the data
                */
                td:before {
                    content: attr(data-title);
                }
            }
        }

        #tabs-0 {
            display: block;
        }
    }
}
